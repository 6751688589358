.services .icon-box {
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 6px;
    background: #252525;
    transition: 0.3s;
    position: relative;
  }
  
  .services .icon-box:hover {
    background: #2b2b2b;
  }


  .services .icon-box i {
    float: left;
    color: #e03a3c;
    font-size: 40px;
    line-height: 0;
  }
  
  .services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .icon-box h4 a {
    color: #fff;
    transition: 0.3s;
    text-decoration: none;
  }
  
  .services .icon-box h4 a:hover {
    text-decoration: underline;
    color: #f7686b;
  }
  
  .services .icon-box .icon-box:hover h4 a {
    color: #e03a3c;
  }
  
  .services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
  }


