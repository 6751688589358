#mission{
    background-color: rgb(241, 247, 247);
}

#mission h4{
    color: #8fc04e;
}
.cvalues {
    list-style-type: none ;
    

}

.item{
    margin-left:8px ;
}

.icon{
    color: #8fc04e;
}

.mission-bottom .video-box-mission {
    background: url("./mission.jpg") center center no-repeat;
    background-size: cover;
    min-height: 400px;
}

.mission-bottom .box-heading h4 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0 0 15px 0;
    background: #f3f8ec;
    color: #8fc04e;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }
  
  .mission-bottom .box-heading h3 {
    font-size: 28px;
    font-weight: 700;
    color: #353535;
    margin-bottom: 15px;
  }
  

  .icon-box-list{
    list-style: none;
    padding-left: 0;
  }

  .icon-box-list li{
    position: relative; /* Set position to relative for the ::before pseudo-element */
    padding-left: 20px; /* Add padding for the icon */
  }

  .icon-box-list li::before {
    content: '\f058'; /* Required for pseudo-elements */
    position: absolute; /* Position the icon */
    left: 0; /* Position the icon to the left of the list item */
    top: 50%; /* Center the icon vertically */
    transform: translateY(-50%); /* Adjust vertically */
    display: inline-block;
    width: 16px; /* Adjust icon width */
    height: 16px; /* Adjust icon height */
    margin-right: 5px;
    color: #8fc04e;
    /* font-size: 32px; */
    align-self: center;
  }

  
  .mission-bottom .video-box {
    /* background: url("./abcd.jpg") center center no-repeat; */
    background-size: cover;
    min-height: 400px;
  }
  

  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }