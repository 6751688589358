body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  text-decoration: none;
  color: #8fc04e;
}

a:hover {
  color: #a7cd74;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}


section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e03a3c;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

.section-bg {
  padding: 120px 0;
  color: #fff;
}

.section-bg:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}
