#mission{
    background-color: rgb(241, 247, 247);
}

#mission h4{
    color: #8fc04e;
}

.col-banner .description{
    margin-bottom: 5px !important;
    /* background-color: red !important; */
}


.col-list{
    list-style: none;
    padding-left: 0;
}
.col-list li{
    margin-bottom: 5px;
}
.col-list strong{
    margin-right: 5px;
}