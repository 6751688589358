.techies-slider .swiper-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.techies-slider .swiper-wrapper .swiper-slide{
    margin: 1em;
}
.clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color:none;
    opacity: 1;
    border: 1px solid #e03a3c;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #e03a3c;
  }
  

  .techies .row div{
    border: 1px solid rgba(207, 204, 204, 0.932);
    margin: 5px;
    padding: 5px;
  }

  .techies .row div img{
    opacity: 1;
    transition: 0.3s;
    /* filter: grayscale(100); */
  }

  .techies .row div img:hover{
    opacity: 1;
    filter: none;
    cursor: pointer;
  }