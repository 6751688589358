.about .box-heading h4 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0 0 15px 0;
    background: #f3f8ec;
    color: #8fc04e;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }
  
  .about .box-heading h3 {
    font-size: 28px;
    font-weight: 700;
    color: #353535;
    margin-bottom: 15px;
  }
  
  .about .icon-box {
    /* margin-top: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    /* padding: 10px; */
    /* margin-bottom: 10px; */
  }
  
  .about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 2px solid #d6e8bf;
    border-radius: 50px;
    transition: 0.5s;
  }
  
  .about .icon-box .icon i {
    color: #8fc04e;
    font-size: 32px;
  }
  
  .about .icon-box:hover .icon {
    background: #8fc04e;
    border-color: #8fc04e;
  }
  
  .about .icon-box:hover .icon i {
    color: #fff;
  }
  
  .about .icon-box .title {
    margin-left: 85px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .about .icon-box .title a:hover {
    color: #8fc04e;
  }
  
  .about .icon-box .description {
    margin-left: 5px;
    line-height: 24px;
    font-size: 14px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 35px; */
    height: 35px;
  }
  
  .about .video-box {
    /* background: url("./abcd.jpg") center center no-repeat; */
    background-size: cover;
    min-height: 400px;
  }

  .about .video-box-2 {
    background: url("./abcd.jpg") center center no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  

  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }