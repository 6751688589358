#footer {
    background: url(/src//assets/footer-bg.jpg) center center no-repeat;
    color: #fff;
    font-size: 14px;
    position: relative;
}

#footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(15, 15, 15, 0.75);
    z-index: 1;
}

#footer .footer-bottom {
    border-top: 1px solid #282828;
    z-index: 2;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
}

#footer .copyright {
    text-align: center;
    float: left;
}

#footer .credits {
    float: right;
    text-align: center;
    font-size: 13px;
}