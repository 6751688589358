#hero {
    width: 100%;
    height: 60vh;
    /* background: url(/src/assets/hero-bg.jpg) top center; */
    background-size: cover;
    position: relative;
    padding-top: 82px;
  }


  
  #hero:before {
    content: "";
    background: rgba(71, 70, 70, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  #hero .container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* text-align: center; */
    padding: 0 15px;
  }

  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
  }

  #hero h2 {
    color: #fff;
    margin: 10px 0 0 0;
    font-size: 24px;
  }


  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #8fc04e;
    color: #fff;
  }
  
  #hero .btn-get-started:hover {
    background: #8fc04e;
  }
  
  @media (max-width: 400px) {
    #hero {
      text-align: center;
      padding-top: 58px;
    }
  
    #hero h1 {
      font-size: 28px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }


  #after-hero{
    z-index: 1;
  }

