.offer-section .box-heading{
  align-items: center !important;
  /* background-color: #8fc04e; */
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.offer-section .box-heading h4 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0 0 15px 0;
    background: #f3f8ec;
    color: #8fc04e;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    align-items: center;
  }

  .offer-section .box-heading h3 {
    font-size: 28px;
    font-weight: 700;
    color: #353535;
    margin-bottom: 15px;
  }
  
  .offer-section .icon-box {
    /* margin-top: 2px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 10px; */
    margin-bottom: 8px;
  }
  
  .offer-section .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 2px solid #d6e8bf;
    border-radius: 50px;
    transition: 0.5s;
  }
  
  .offer-section .icon-box .icon i {
    color: #8fc04e;
    font-size: 32px;
  }
  
  .offer-section .icon-box:hover .icon {
    background: #8fc04e;
    border-color: #8fc04e;
  }
  
  .offer-section .icon-box:hover .icon i {
    color: #fff;
  }
  
  .offer-section .icon-box .title {
    margin-left: 85px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .offer-section .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .offer-section .icon-box .title a:hover {
    color: #8fc04e;
  }
  
  .offer-section .icon-box .description {
    margin-left: 5cqb;
    line-height: 20px;
    font-size: 14px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 35px; */
    height: 35px;
  }

  .offer-section .video-box img{
    height: 100%;
    width: 100%;
  }
  
  .offer-section .video-box {
    /* background: url("./offer-section.jpg") center center no-repeat; */
    /* background-size: contain; */
    /* background-color: red; */

    /* height: 680px; */
    /* width: 500px; */
    /* object-fit: cover; */
    /* object-fit: contain; */
    /* float: left; */
    /* padding: 10px; */
    
  }
  

  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }