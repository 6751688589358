.page-intro{
    position: relative;
    z-index: 2;
    /* margin: 2em; */
    margin-top: -3em;
    color: #ffffff;
    padding: 2rem;
    /* background: linear-gradient(to right,#303f9f 0%, #3d50c5 45%, #303f9f 100%); */
    background-color: #8fc04e;
    background-size: 220% auto;
    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 700;
    box-shadow: -5px 5px 35px 0px rgba(0, 0, 0, 0.2);
}


.page-summary{
    line-height: 1.8rem;
    margin-top: 0px;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}